<template>
  <div class="news" id="news">
    <div class="row">
      <div class="col-12">
        <div class="jumbotron innner_banner header-style-1" style="background-image: url(https://res.cloudinary.com/pnjkenya/image/upload/v1651578858/blog-1_urvxg4.jpg);">
          <div class="container-header"><h3 class="inner_banner_header">Sport News</h3></div>
        </div>
      </div>
    </div>
<!-- This is for large desktops -->
     <div class="container-fluid d-none d-lg-block">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-body">
                  <VueRssFeed :feedUrl="feedUrl" :name="name" :limit="limit"/>                   
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <NewsCategories />
          
        </div>
      </div>
    </div>

<!-- now this is for mobiles -->

<div class="container-fluid d-lg-none">
  <div class="row">
    <div class="col-12">
        <NewsCategories />
    </div>
  </div>  

  <div class="row my-4">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <VueRssFeed :feedUrl="feedUrl" :name="name" :limit="limit"/>  
          </div>
      </div>
   </div>
  </div>
</div>

  </div>
</template>

<script>

import VueRssFeed from "./VueRssFeed.vue";
import NewsCategories from "../components/NewsCategories.vue";


const PIXALS_FROM_BOTTOM = 200;
export default {

  name: "News",
  components: {
    VueRssFeed,
    NewsCategories,
  },
  props: {
    feedUrl: String,
    name: String,
    limit: Number
  },

  data() {
    
    return {
      feedUrl: "https://www.sportskeeda.com/feed",
      name: "",
      limit: 4,
    }
  },
  mounted() {

    this.scrollEvent = window.addEventListener("scroll", () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - PIXALS_FROM_BOTTOM) {
        this.increaseLimit();
      }
    });

  },

  destroyed() {
    if (this.scrollEvent) {
      window.removeEventListener(this.scrollEvent);
    }
  },
  
  methods: {
    
    increaseLimit(loadMore = 5) {
      this.limit += loadMore;
    },
    loadExample(evt) {
      evt.preventDefault();
      this.rssFeedForm.feedUrl = evt.toElement.href;
      this.feedUrl = evt.toElement.href;
    }
  },
  watch: {
    getFeedUrl() {
      return this.feedUrl;
    }
  }
  

  
}
</script>

<style scoped>


/* @media screen and (min-width: 768px) { */
   /* #news {
  padding-top: 95px;
}
  }
@media screen and (max-width: 768px) {
    #news {
    padding-top: 70px;
  }
  } */



  .inner_banner_header {
  color: #fff;
    font-size: 36px !important;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.container-header {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.innner_banner:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    opacity: 0.7;
    position: absolute;
    background-color: #000;
}
.innner_banner{
    z-index: 1;
    float: left;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    padding: 100px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>