<template>
  <div class="news" id="PrivacyPolicy">
   <div class="container-fluid my-3">
     <div class="row">
       <div class="col-lg-9 col-12">
         <div class="card">
            <div class="card-body">
              <div class="text-center">
                <img src="   https://cdn-icons-png.flaticon.com/512/5642/5642158.png " style="width: 150px;" alt="faq" class="img-fluid" />
                <h5 class="mt-4">How To Make Payments</h5>
              </div>

              <ol>
                <li>
                  <p>Go to the subscription page </p>

                  <img src="@/assets/vip/subpage.png" alt="" class="img-fluid" />
                </li>
                <li>
                  <p>Choose your subscription preferred plan</p>
                </li>
                
                
                <li>
                  <p>In the popup, Choose preferred Payment method i.e mobile money or stripe</p>
                  <img src="@/assets/vip/choosemethod.png" alt="" class="img-fluid" />
                </li>
                
                <li>
                  
                    <p>
                      For mobile money, select your country and proceed to checkout page. Choose your preferred mobile money payment method and make the payment.
                    </p> <br>
                    <img src="@/assets/vip/choosecountry.png" alt="" class="img-fluid" /> <br>

                    <p>
                      - You can also Change Payment Method In Flutterwave
                    </p>

                    <img src="@/assets/vip/flutterwavepayments.png" alt="" class="img-fluid" />

                    <p>
                      Follow the instructions on the screen to Complete the Payment
                    </p>
                    <img src="@/assets/vip/completepayment.png" alt="" class="img-fluid" />

                  
                </li>
                
                <li>
                  <p>
                    For stripe, enter your card details and make the payment.
                  </p>
                  
                  <img src="@/assets/vip/stripe.png" alt="" class="img-fluid" />
                </li>

                <li>
                  After successful payment, you will be redirected to the home page where you will be able to access the unlocked tips.
                </li>
              </ol>


              <div class="text-center mt-5">
                <img src="   https://cdn-icons-png.flaticon.com/512/8812/8812076.png " style="width: 80px;" alt="faq" class="img-fluid" />
                <h5 class="mt-4">Have Any Questions / Issues?</h5>
              </div>

              <p class="mt-4">
                - If you have any questions or issues, please contact us via:
                
                <p>Email: <a href="mailto:info@thevipbettingtips.com">info@thevipbettingtips.com</a></p>


                  <p>
              - Or optionally reach us via our social media pages: <br>

            </p>

            <div class="row">
              <div class="col-4 text-center">
                <center>

                <a href="https://www.facebook.com/vipbettingtips254" target="_blank">
                  <i class="fa-brands fa-facebook" style="font-size:30px;"></i>
                </a>
                </center>
              </div>
              <div class="col-4 text-center">
                <a href="https://www.tiktok.com/@sportsreels254" target="_blank">
                  <i class="fa-brands fa-tiktok" style="font-size:30px; color:black;"></i>
                </a>
              </div>
              <div class="col-4 text-center">
                <a href="https://www.instagram.com/sportsreels254/reels/" target="_blank">
                  <i class="fa-brands fa-instagram" style="font-size:30px; color: orangered"></i>
                </a>
              </div>
            </div>
                 
              </p>
            </div>
         </div>
       </div>

        <Offers />
     </div>
   </div>
  </div>
</template>

<script>

import Offers from "../components/Offers.vue";

import store from '../store'


export default {
  name: "PrivacyPolicy",
  components: {
    Offers,

  },
  created() {
    store.dispatch("setvisibility", true);
  },
}

</script>
