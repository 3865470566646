<template>
  <div class="news" id="PrivacyPolicy">
   <div class="container-fluid my-3">
     <div class="row">
       <div class="col-lg-9 col-12">
         <div class="card">
           <div class="card-body">
                  <center><h3 style="font-weight:400; border-bottom: solid 3px #0679B0">Privacy and Cookie Policy</h3></center>

                  <p>
                    This Privacy Policy applies in respect of all personal information you provide to us or that we collect in connection with your use of our service.

                <br>
                The personal information we collect from you may include: <br>

                </p>

                <ul class="my-2">
                  <li>
                    information you submit to us when you choose to register for an account with us, such as your user name and email address;
                  </li>

                  <li>
                    any information you provide to us if you contact us;

                  </li>

                  <li>
                    any information you provide to us if you fill out a form or enter a competition via our service including,
                    if requested, your PayPal and/or Skrill email addresses, which is required by us in order to make competition payments to you if you win the competition and, if you do not provide this information, we may not be able to provide you with the prize; and
                  </li>

                  <li>
                    information about the device you use to access our service such as your IP address and geolocation.

                  </li>
                </ul>

                <p>
                  You are not required to provide all of this information, but if you do not, you may not be able to experience all of the services and features available on our service.

                  We may also observe your use of our service and derive certain information from this which may include personal data such as your access times and browsing activity.

                </p>


              <!-- another section -->
             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">How is your personal information used?</h4>

             <ul>
               <li>
                 provide you with personalised visits to our service for example by displaying targeted advertising and content which we believe likely to be relevant and of interest to you;
               </li>

               <li>
                 develop our service and enhance its usability and functionality and improve the user-experience;

               </li>

               <li>
                 provide you with services which you have signed up for;
               </li>

               <li>
                 share your personal information with our partners (detailed below);
               </li>

               <li>
                 recommend goods, services or promotions which may be of interest to you (but only where you have consented to be contacted for such purposes);

               </li>

               <li>
                 make payments to you if you win a competition
               </li>

               <li>
                 contact you
               </li>
             </ul>


                          <!-- another section -->
             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">With whom is your personal information shared?</h4>

             <p>
               We may also disclose depersonalised data (such as aggregated statistics) about the users of our service in order to describe our sales, customers, traffic patterns and other site information to prospective partners, advertisers, investors and other reputable third parties and for other lawful purposes such as to analyse and understand site usage, but these statistics will include no personally identifying information.

              We may occasionally be required by law, court order or governmental authority to disclose certain types of personal information.  Examples of the type of situation where this would occur would be:

              in the administration of justice; or
              where we have to defend ourselves legally.
             </p>

                           <!-- another section -->
             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">The requirements of data protection laws</h4>

             <p>
               We regard the lawful and correct treatment of your personal information by us as very important to our successful operation, and to maintaining confidence between us and our users. We ensure that our organisation treats personal information lawfully and correctly. To this end we fully endorse and adhere to the data protection principles set out in data protection legislation. In particular, without your consent or first amending this Privacy Policy to reflect any change in our usage of personal information:
             </p>

             <ul>
               <li>we will not use your personal information for any purpose that is incompatible with this Privacy Policy;</li>
               <li>we will only collect sufficient personal information for the uses set out above;</li>
               <li>we will endeavour to keep your personal information up-to-date;</li>
               <li>we will operate appropriate technical and organisational processes to protect your personal information against unauthorised or unlawful access or processing and against accidental loss or destruction. The measures we take are described elsewhere in this Privacy Policy; and</li>
               <li>
                 we will not transfer your personal information to a country outside the European Economic Area unless safeguards are in place to protect your personal information to the standards that apply within the EEA including via standard contractual clauses and Privacy Shield or where the transfer is necessary to administer a competition you have entered via our service (for example, it is sometimes necessary to send your username to a bookmaker outside the EEA if you enter a competition administered by that bookmaker).

               </li>
             </ul>


                           <!-- another section -->
             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Security</h4>

             <p>We are committed to protecting the security of your personal information. We use a variety of security technologies and procedures to help protect your personal information from unauthorised access, use, or disclosure.</p>
             <p>For example, we store the personal information you provide on secure computer systems with limited access that are located in facilities to which access is limited.

              If you choose to set up an account with our service, it is your responsibility to ensure the security of your password and not to reveal this information to others.
              </p>


             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Retention</h4>

             <blockquote>
               We will hold your personal data for so long as you download and use our service and for no more than five years after.  We will endeavor to delete any personal data sooner where it is not necessary for us to hold this, but please be aware that we may hold personal data for longer if we are under a legal obligation to do so or where we have a reasonable belief that it is necessary to do so for business or legal reasons.
             </blockquote>

             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Your rights</h4>

             <p>You have the following rights in relation to your personal data:</p>

             <ul>
               <li>a right of access to a copy of the information comprised in your personal data;</li>
               <li>a right to access your personal data in machine-readable format and to request that we transfer this to a third party data controller;</li>
               <li>a right to object to processing that is likely to cause or is causing damage or distress;</li>
               <li>a right to ask us not to process your personal data for direct marketing purposes;</li>
               <li>a right in certain circumstances to have inaccurate personal data rectified, blocked, erased or destroyed;</li>
               <li>a right to claim compensation for damages caused by a breach of data protection legislation; and</li>
               <li>a right to lodge a complaint to the Information Commissioner's Office in respect of our use of your personal data. </li>
             </ul>

             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Under 18s</h4>
             <p>Our service is for users who are aged 18 years old and over. We do not knowingly collect personal data from under 18s. </p>


             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Links to other websites</h4>
             <p>
               Our service may contain links to, or integration with, other websites or apps such as bookmaker's website and apps and social media sites such as Facebook, Twitter, Google+ and YouTube. We are not responsible for the content, security, practices or privacy policies employed by other websites and apps. We encourage you to carefully review these third parties' privacy policies so that you know how they will collect, use, and share your information. In particular, when you open an account with a  bookmaker, that bookmaker's own privacy policy will apply to personal data you provide via that account.
             </p>

             <h4 style="font-weight:400; border-bottom: solid 3px #0679B0">Contact us</h4>

             <p>If you have questions regarding this policy or our handling of your personal information, please contact us here. We will promptly address your concern and strive to reach a satisfactory resolution.</p>
             <ul>
               <li> <i class="fas fa-envelope"></i> Email: <a href="mailto:info@thevipbettingtips.com">info@thevipbettingtips.com</a></li>
             </ul>
            <p>
              Or optionally reach us via our social media pages: <br>

            </p>

            <div class="row">
              <div class="col-4 text-center">
                <center>

                <a href="https://www.facebook.com/vipbettingtips254" target="_blank">
                  <i class="fa-brands fa-facebook" style="font-size:30px;"></i>
                </a>
                </center>
              </div>
              <div class="col-4 text-center">
                <a href="https://www.tiktok.com/@sportsreels254" target="_blank">
                  <i class="fa-brands fa-tiktok" style="font-size:30px; color:black;"></i>
                </a>
              </div>
              <div class="col-4 text-center">
                <a href="https://www.instagram.com/sportsreels254/reels/" target="_blank">
                  <i class="fa-brands fa-instagram" style="font-size:30px; color: orangered"></i>
                </a>
              </div>
            </div>
           </div>
         </div>
       </div>

        <Offers />
     </div>
   </div>
  </div>
</template>

<script>

import Offers from "../components/Offers.vue";

import store from '../store'


export default {
  name: "PrivacyPolicy",
  components: {
    Offers,

  },
  created() {
    store.dispatch("setvisibility", true);
  },
}

</script>
